
import React, { Component } from 'react';
import { NumericFormat } from 'react-number-format';

export default class RoomsDetail extends Component {
    render() {
        const { data } = this.props;
        var s1 = JSON.parse(JSON.stringify(data.facilities));
        var roomFacility = eval('(' + s1 + ')');
        // if (data.firstname !== null && data.maintenance === 'Y') {
        return (
            <div className="card m-1" >
                <div className="card-header bg-info text-sm">
                    <div className="row">
                        <div className="col-md-6 text-start">
                            <span className="h4"><strong>{data.roomname}</strong></span>
                        </div>
                        <div className="col-md-6 text-end"><span className="badge bg-light text-dark text-md"><strong>{data.firstname !== null && data.maintenance !== 'Y' ? data.firstname + " " + data.lastname : data.firstname !== null && data.maintenance === 'Y' ?
                            'Renovasi' :
                            data.firstname === null && data.maintenance === 'Y' ?
                                'Renovasi' :
                                'Kosong'}</strong></span></div>
                    </div></div>
                <div className={data.firstname !== null && data.maintenance !== 'Y' ?
                    'card-body text-dark bg-warning' :
                    data.firstname !== null && data.maintenance === 'Y' ?
                        'card-body text-light bg-dark' :
                        data.firstname === null && data.maintenance === 'Y' ?
                            'card-body text-light bg-dark' :
                            'card-body text-dark bg-secondary'}>

                    {data.firstname !== null ? <p className="card-text">Masuk : {data.contractdate}</p> : <p></p>}
                    {data.firstname !== null ? <p className="card-text">Pembayaran Berikutnya : {data.last_payment}</p> : <p></p>}
                    <p className="card-text">Harga Sewa : <NumericFormat value={data.roomprices.toFixed(0)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} /></p>
                    {/* {data.meal_bill !== null ? <p className="card-text">Tagihan Makan : <NumericFormat value={data.meal_bill.toFixed(0)} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} /></p> : <p></p>} */}
                    <div className="collapse" id={'roomSpecs-' + data.roomname.replace("Kamar ", "")}>
                        <table className="table table-light table-striped table-bordered">
                            <thead className="text-center">
                                <tr>
                                    <th className="table-light">Spesifikasi</th>
                                    <th className="table-light">Deskripsi</th>
                                </tr>

                            </thead>
                            <tbody className="text-center">
                                <tr>
                                    <td>Luas Kamar</td>
                                    <td>{roomFacility.luas}</td>
                                </tr>
                                <tr>
                                    <td>Air Conditioner</td>
                                    <td>{roomFacility.ac ? <i className="mdi h3 mdi-check-circle text-success"></i> : <i className="mdi h3 mdi-close-circle text-danger"></i>}</td>
                                </tr>
                                <tr>
                                    <td>Laundry</td>
                                    <td>{roomFacility.laundry ? <i className="mdi h3 mdi-check-circle text-success"></i> : <i className="mdi h3 mdi-close-circle text-danger"></i>}</td>
                                </tr>
                                <tr>
                                    <td>Petugas Kebersihan</td>
                                    <td>{roomFacility.room_cleaning ? <i className="mdi h3 mdi-check-circle text-success"></i> : <i className="mdi h3 mdi-close-circle text-danger"></i>}</td>
                                </tr>
                                <tr>
                                    <td>Air Panas (Shower)</td>
                                    <td>{roomFacility.water_heater ? <i className="mdi h3 mdi-check-circle text-success"></i> : <i className="mdi h3 mdi-close-circle text-danger"></i>}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                </div>
                <button type="button" className="btn btn-primary bg-primary text-light card-footer" data-bs-toggle="collapse" data-bs-target={'#roomSpecs-' + data.roomname.replace("Kamar ", "")} aria-expanded="false" aria-controls={'roomSpecs-' + data.roomname.replace("Kamar ", "")} >
                    Spesifikasi Kamar
                </button>
            </div>
        )
    }
}