// Header.js
import React, { Component } from 'react';
import { useNavigate } from 'react-router-dom';
import {axiosClient} from '../api/axiosClient';
import Swal from 'sweetalert2'



const Header = () => {
    const navigate = useNavigate();

        const handleLogout = async () => {
            Swal.fire({
                title: "Apakah Anda yakin akan Keluar?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                cancelButtonText: "Batalkan",
                confirmButtonText: "Keluar"
            }).then((result) => {
                if (result.isConfirmed) {
                    axiosClient.delete(`/auth`);
                    localStorage.removeItem('token');
                    Swal.fire({
                        title: "Terhapus!",
                        text: "Kamar berhasil dihapus!",
                        icon: "success",
                        timer: 1500
                    });
                    navigate(0);
                }
            });
            
        };


        return (
            <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
                <div
                    className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start"
                >
                    <a className="navbar-brand brand-logo me-5" href="/"
                    ><img src="assets/images/aman-badges.png" className="me-2" alt="logo"
                        /></a>
                    <a className="navbar-brand brand-logo-mini" href="/"
                    ><img src="assets/images/aman-logo.png" alt="logo"
                        /></a>
                </div>
                <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
                   
                    <ul className="navbar-nav mr-lg-2">
                        
                        <li className="nav-item mt-2">
                            <h1 className="text-light badge bg-primary"><b>Wisma Cemara</b></h1>
                        </li>
                    </ul>
                    <ul className="navbar-nav navbar-nav-right">
                       
                        <li className="nav-item nav-profile dropdown">
                            <a
                                className="nav-link dropdown-toggle"
                                href="#"
                                data-bs-toggle="dropdown"
                                id="profileDropdown"
                            >
                                <img src="assets/images/faces/face28.jpg" alt="profile" />
                            </a>
                            <div
                                className="dropdown-menu dropdown-menu-right navbar-dropdown"
                                aria-labelledby="profileDropdown"
                            >
                                {/* <a className="dropdown-item">
                                    <i className="ti-settings text-primary"></i> Settings
                                </a> */}
                                <a className="dropdown-item" onClick={handleLogout}>
                                    <i className="ti-power-off text-primary"></i> Logout
                                </a>
                            </div>
                        </li>
                    </ul>
                    <button
                        className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
                        type="button"
                        data-toggle="offcanvas"
                    >
                        <span className="icon-menu"></span>
                    </button>
                </div>
            </nav>
        )
    }
export default Header;