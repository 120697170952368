
import React, { useRef, useState, useEffect } from 'react';
import { Modal } from "bootstrap";
import { axiosClient, axiosClientUploads } from 'api/axiosClient';
import { apiUrl } from 'config';
import ModalImage from "react-modal-image";
import Swal from 'sweetalert2'


function Pengguna() {
    const [users_detail, setUserDetail] = useState([{}]);

    const fetchUsers = async () => {
        setUserDetail([{}]);
        try {
            const response = await axiosClient.get(`/users/list`);
            setUserDetail(response.data.data);
        } catch (error) {
            console.error("Error fetching Users:", error);
        }
    };
    useEffect(() => {
        fetchUsers();

    }, []);

    const [newUser, setNewUser] = useState({
        firstname: null,
        lastname: null,
        username: null,
        password: null,
        role: null
    });
    const [newUserId, setNewUserId] = useState('');

    const [editUser, setEditUser] = useState({
        firstname: null,
        lastname: null,
        username: null,
        password: null,
        role: null
    });
    const [editUserId, setEditUserId] = useState('');

    const [lastIdUser, setLastIdUser] = useState('');
    const [currentUser, setCurrentUser] = useState({});
    const refModalUserAdd = useRef()
    const refModalUserEdit = useRef()

    const showModalUserAdd = () => {
        const modalEle = refModalUserAdd.current
        const bsModal = new Modal(modalEle, {
            backdrop: 'static',
            keyboard: false
        })
        fetchLastIdUsers();
        bsModal.show()
    }

    const hideModalUserAdd = () => {
        const modalEle = refModalUserAdd.current
        const bsModal = Modal.getInstance(modalEle)

        initData();
        bsModal.hide()
    }

    const initData = () => {
        setCurrentUser({});
        setNewUser({
            firstname: null,
            lastname: null,
            username: null,
            password: null,
            role: null
        });
        setEditUser({
            firstname: null,
            lastname: null,
            username: null,
            password: null,
            role: null
        });
        setLastIdUser("");
        // console.log("initial");
    }

    const showModalUserEdit = (id) => {
        const modalEle = refModalUserEdit.current
        const bsModal = new Modal(modalEle, {
            backdrop: 'static',
            keyboard: false
        });
        filterUsers(id);
        bsModal.show()
    }

    const hideModalUserEdit = () => {
        const modalEle = refModalUserEdit.current
        const bsModal = Modal.getInstance(modalEle)
        initData();
        //navigate("/pengguna")
        bsModal.hide()
    }

    const postData = async () => {
        const formData = new FormData();
        formData.append('file', newUser.customerpic.files[0]);

        try {
            
                let payload = {
                    "firstname": newUser.firstname,
                    "lastname": newUser.lastname,
                    "username": newUser.username,
                    "password": newUser.password,
                    "role": newUser.role
                }
                axiosClient.post(`/users`, payload);
      
            Swal.fire({
                title: "Berhasil Ditambah!",
                text: `Data Pengguna atasnama ${editUser.firstname} berhasil ditambahkan!`,
                icon: "success",
                timer: 1500
            });
        } catch (error) {
            console.error("Error fetching UserId:", error);
        }
        fetchUsers();
    }

    const updateData = async () => {
        
                let payload = {
                    "firstname": editUser.firstname,
                    "lastname": editUser.lastname,
                    "username": editUser.username,
                    "password": editUser.password,
                    "role": editUser.role
                }
                axiosClient.put(`/users/${editUser.id}`, payload);
           
            Swal.fire({
                title: "Berhasil Diubah!",
                text: `Data Pengguna atasnama ${editUser.firstname} berhasil diubah!`,
                icon: "success",
                timer: 1500
            });
       
        fetchUsers();
    }

    const handleSubmitAdd = () => {
        postData();
        hideModalUserAdd();
    };

    const handleSubmitEdit = () => {
        updateData();
        hideModalUserEdit();

    };
    const fetchLastIdUsers = async () => {
        try {
            const response = await axiosClient.get(`/users/last`);
            setLastIdUser(response.data.data);
        } catch (err) {
            console.error("Error fetching UserId:", err);
            Swal.fire({
                title: "Error!",
                text: `${err}`,
                icon: "error",
                timer: 1500
            });
        }
    };

    const filterUsers = (id) => {

        let filtered = users_detail.filter((o) => {
            if (o.id === id) return o;
        })
        setCurrentUser(filtered[0]);
        setEditUser(filtered[0]);
        // console.log(editUserPlnId,editUserPlnNames);
    };

    const deleteUser = (id) => {
        Swal.fire({
            title: "Apakah Anda yakin?",
            text: "Jika dilakukan, data pengguna tidak bisa dipulihkan lagi!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "Batalkan",
            confirmButtonText: "Ya, Mengerti"
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/users/${id}`)
                Swal.fire({
                    title: "Terhapus!",
                    text: "Pengguna berhasil dihapus!",
                    icon: "success",
                    timer: 1500
                });
            }
        });
        fetchUsers();
    }

    return (
        <div>
            <div>
                <div className="row">
                    <div className="col-md-12 grid-margin">
                        <div className="row">
                            <div className="col-12 col-xl-6 mb-4 mb-xl-0">
                                <h3 className="font-weight-bold">Daftar Pengguna</h3>
                            </div>
                            <div className="col-12 col-xl-6 mr-4">
                                <div className="justify-content-end d-flex">
                                    <button className="btn btn-md btn-primary" onClick={showModalUserAdd}><i className="mdi mdi mdi-home-plus-outline"></i> Tambah Pengguna</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="table-responsive table-bordered">
                            <table className="table table-bordered table-striped table-light">
                                <thead className="table-primary text-center">
                                    <tr>
                                        <th>Pengoperasian</th>
                                        <th>Nama User</th>
                                        <th>Username</th>
                                        <th>Hak Akses</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {users_detail ? users_detail.map(user => (

                                        <tr key={parseInt(user.id)}>
                                            <td>
                                                <div className="button-group text-center">
                                                    <button className="btn btn-outline-warning btn-square btn-sm" onClick={() => showModalUserEdit(user.id)}><span className="text-lg"><i className="mdi mdi-information-variant"></i> Info</span></button>
                                                    <button className="btn btn-outline-danger btn-square btn-sm" onClick={() => deleteUser(user.id)}><span className="text-lg"><i className="mdi mdi-delete"></i> Hapus</span></button>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                {user.firstname + " " + user.lastname}
                                            </td>
                                            <td className="text-center">
                                                {user.username}
                                            </td>
                                            <td className="text-center">
                                                {user.rolename}
                                            </td>
                                            
                                        </tr>
                                    )) : (<tr className="bg-dark-gray" key="1">
                                        <td>
                                            <p className="placeholder-glow">
                                                <span className="placeholder col-12"></span>
                                            </p>
                                        </td>
                                        <td>
                                            <p className="placeholder-glow">
                                                <span className="placeholder col-12"></span>
                                            </p>
                                        </td>
                                        <td>
                                            <p className="placeholder-glow">
                                                <span className="placeholder col-12"></span>
                                            </p>
                                        </td>
                                        <td>
                                            <p className="placeholder-glow">
                                                <span className="placeholder col-12"></span>
                                            </p>
                                        </td>
                                        
                                    </tr>)}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="modal fade" ref={refModalUserAdd} tabIndex="-1" >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header bg-primary text-light">
                                <h5 className="modal-title"><i className="mdi mdi mdi-home-plus-outline"></i> Tambah Pengguna</h5>
                                <button type="button" className="btn btn-sm text-light" onClick={hideModalUserAdd}>X</button>
                            </div>
                            <div className="modal-body">
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="id-pengguna">ID Pengguna</span>
                                    <input type="text" className="form-control" disabled id="id-pengguna" aria-describedby="id-pengguna" onChange={(e) => setNewUserId(e.target.value)} value={lastIdUser}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="nama-depan-pengguna">Nama Depan</span>
                                    <input type="text" className="form-control" id="nama-depan-pengguna" aria-describedby="nama-depan-pengguna" placeholder="Nama Depan Pengguna" onChange={(e) => setNewUser({ ...newUser, firstname: e.target.value })} value={newUser.firstname}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="nama-belakang-pengguna">Nama Belakang</span>
                                    <input type="text" className="form-control" id="nama-belakang-pengguna" aria-describedby="nama-belakang-pengguna" placeholder="Nama Belakang Pengguna" onChange={(e) => setNewUser({ ...newUser, lastname: e.target.value })} value={newUser.lastname}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="username">Username</span>
                                    <input type="text" className="form-control" id="username" aria-describedby="username" placeholder="Username" onChange={(e) => setNewUser({ ...newUser, username: e.target.value })} value={newUser.username}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="password">Password</span>
                                    <input type="password" className="form-control" id="password" aria-describedby="password" placeholder="Password" onChange={(e) => setNewUser({ ...newUser, password: e.target.value })} value={newUser.password}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="confirm-password">Konfirmasi Password</span>
                                    <input type="password" className="form-control" id="confirm-password" aria-describedby="confirm-password" placeholder="Confirm Password" onChange={(e) => e.target.value.length > 3 && e.target.value === newUser.password ? e.target.className = "form-control bg-success" : e.target.className = "form-control bg-danger"}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="role">Hak Akses</span>
                                    <select className="form-select text-dark" id="role" aria-describedby="role" onChange={(e) => setNewUser({ ...newUser, role: e.target.value })} value={newUser.role}>
                                        <option value="" disabled selected="selected">--- Pilih Hak Akses ---</option>
                                        <option value="2">Kost Owner</option>
                                        <option value="3">Finance</option>
                                        <option value="4">Staff</option>
                                    </select>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger text-light" onClick={hideModalUserAdd}>Batal</button>
                                <button type="submit" onClick={handleSubmitAdd} className="btn btn-primary">Tambah</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" ref={refModalUserEdit} tabIndex="-1" >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header bg-primary text-light">
                                <h5 className="modal-title"><i className="mdi mdi mdi-home-plus-outline"></i> Ubah Pengguna</h5>
                                <button type="button" className="btn btn-sm text-light" onClick={hideModalUserEdit}>X</button>
                            </div>
                            <div className="modal-body">
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="id-pengguna">ID Pengguna</span>
                                    <input type="text" className="form-control" disabled id="id-pengguna" aria-describedby="id-pengguna" onChange={(e) => setEditUser({ ...editUser, id: e.target.value })} value={editUser.id}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="nama-depan-pengguna">Nama Depan</span>
                                    <input type="text" className="form-control" id="nama-depan-pengguna" aria-describedby="nama-depan-pengguna" placeholder="Nama Depan Pengguna" onChange={(e) => setEditUser({ ...editUser, firstname: e.target.value })} value={editUser.firstname}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="nama-belakang-pengguna">Nama Belakang</span>
                                    <input type="text" className="form-control" id="nama-belakang-pengguna" aria-describedby="nama-belakang-pengguna" placeholder="Nama Belakang Pengguna" onChange={(e) => setEditUser({ ...editUser, lastname: e.target.value })} value={editUser.lastname}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="username">Username</span>
                                    <input type="text" className="form-control" id="username" aria-describedby="username" placeholder="Username" onChange={(e) => setEditUser({ ...editUser, username: e.target.value })} value={editUser.username}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="password">Password</span>
                                    <input type="password" className="form-control" id="password" aria-describedby="password" placeholder="Password" onChange={(e) => setEditUser({ ...editUser, password: e.target.value })} value={editUser.password}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="confirm-password">Konfirmasi Password</span>
                                    <input type="password" className="form-control" id="confirm-password" aria-describedby="confirm-password" placeholder="Confirm Password" onChange={(e) => e.target.value.length > 3 && e.target.value === editUser.password ? e.target.className = "form-control bg-success" : e.target.className = "form-control bg-danger"}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="role">Hak Akses</span>
                                    <select className="form-select text-dark" id="role" aria-describedby="role" onChange={(e) => setEditUser({ ...editUser, role: e.target.value })} value={editUser.role}>
                                        <option value="" disabled selected="selected">--- Pilih Hak Akses ---</option>
                                        <option value="2">Kost Owner</option>
                                        <option value="3">Finance</option>
                                        <option value="4">Staff</option>
                                    </select>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger text-light" onClick={hideModalUserEdit}>Batal</button>
                                <button type="submit" onClick={handleSubmitEdit} className="btn btn-primary">Ubah</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div>

    );
}
export default Pengguna;
