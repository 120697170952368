
import React, { useRef, useState, useEffect } from 'react';
import { Modal } from "bootstrap";
import { axiosClient, axiosClientUploads } from 'api/axiosClient';
import { apiUrl } from 'config';
import ModalImage from "react-modal-image";
import Swal from 'sweetalert2'


function Penghuni() {
    const [customers_detail, setCustomerDetail] = useState([{}]);

    const fetchCustomers = async () => {
        setCustomerDetail([{}]);
        try {
            const response = await axiosClient.get(`/api/penghuni`);
            setCustomerDetail(response.data.data);
        } catch (error) {
            console.error("Error fetching Customers:", error);
        }
    };
    useEffect(() => {
        fetchCustomers();

    }, []);
    
    const [newCustomer, setNewCustomer] = useState({
        firstname: null,
        lastname: null,
        gender: null,
        address: null,
        provincies: null,
        regencies: null,
        districts: null,
        villages: null,
        nik: null,
        emergencycontact: null,
        emergencycontactname: null,
        customerpic: null,
        mobilenumber: null,
        password: null,
        roomid: null,
        contractdate: null
    });
    const [newCustomerId, setNewCustomerId] = useState('');

    const [editCustomer, setEditCustomer] = useState({
        firstname: null,
        lastname: null,
        gender: null,
        address: null,
        provincies: null,
        regencies: null,
        districts: null,
        villages: null,
        nik: null,
        emergencycontact: null,
        emergencycontactname: null,
        customerpic: null,
        mobilenumber: null,
        password: null,
        roomid: null,
        contractdate: null
    });
    const [editCustomerId, setEditCustomerId] = useState('');

    const [lastIdCustomer, setLastIdCustomer] = useState('');
    const [currentCustomer, setCurrentCustomer] = useState({});
    const refModalCustomerAdd = useRef()
    const refModalCustomerEdit = useRef()

    const showModalCustomerAdd = () => {
        const modalEle = refModalCustomerAdd.current
        const bsModal = new Modal(modalEle, {
            backdrop: 'static',
            keyboard: false
        })
        fetchLastIdCustomers();
        fetchRooms();
        bsModal.show()
    }

    const hideModalCustomerAdd = () => {
        const modalEle = refModalCustomerAdd.current
        const bsModal = Modal.getInstance(modalEle)
        
        initData();
        bsModal.hide()
    }

    const initData = () => {
        setCurrentCustomer({});
        setNewCustomer({ 
            firstname: null,
            lastname: null,
            gender: null,
            address: null,
            provincies: null,
            regencies: null,
            districts: null,
            villages: null,
            nik: null,
            emergencycontact: null,
            emergencycontactname: null,
            customerpic: null,
            mobilenumber: null,
            password: null,
            roomid: null,
            contractdate: null
        });
        setEditCustomer({
            firstname: null,
            lastname: null,
            gender: null,
            address: null,
            provincies: null,
            regencies: null,
            districts: null,
            villages: null,
            nik: null,
            emergencycontact: null,
            emergencycontactname: null,
            customerpic: null,
            mobilenumber: null,
            password: null,
            roomid: null,
            contractdate: null
        });
        setLastIdCustomer("");
        // console.log("initial");
    }

    const showModalCustomerEdit = (id) => {
        const modalEle = refModalCustomerEdit.current
        const bsModal = new Modal(modalEle, {
            backdrop: 'static',
            keyboard: false
        });
        fetchRooms();
        filterCustomers(id);
        bsModal.show()
    }

    const hideModalCustomerEdit = () => {
        const modalEle = refModalCustomerEdit.current
        const bsModal = Modal.getInstance(modalEle)
        initData();
        //navigate("/penghuni")
        bsModal.hide()
    }

    const postData = async () => {
        const formData = new FormData();
        formData.append('file', newCustomer.customerpic.files[0]);

        try {
            await axiosClientUploads.post(`/api/uploads/penghuni`, formData,).then((res) => {
                //console.log(res.data.file.filename);
                let payload = {
                    "firstname": newCustomer.firstname,
                    "lastname": newCustomer.lastname,
                    "gender": newCustomer.gender,
                    "address": newCustomer.address,
                    "provincies": newCustomer.provincies,
                    "regencies": newCustomer.regencies,
                    "districts": newCustomer.districts,
                    "villages": newCustomer.villages,
                    "nik": newCustomer.nik,
                    "emergencycontact": newCustomer.emergencycontact,
                    "emergencycontactname": newCustomer.emergencycontactname,
                    "customerpic": res.data.file.filename,
                    "mobilenumber": newCustomer.mobilenumber,
                    "password": newCustomer.password,
                    "roomid": newCustomer.roomid,
                    "contractdate": newCustomer.contractdate
                }
                axiosClient.post(`/api/penghuni`, payload);
            }).catch((err) => {
                console.error(err);
                Swal.fire({
                    title: "Error!",
                    text: `${err}`,
                    icon: "error",
                    timer: 1500
                });
            });
            Swal.fire({
                title: "Berhasil Ditambah!",
                text: `Data Penghuni atasnama ${editCustomer.firstname} berhasil ditambahkan!`,
                icon: "success",
                timer: 1500
            });
        } catch (error) {
            console.error("Error fetching CustomerId:", error);
        }
        fetchCustomers();
    }

    const updateData = async () => {
        // console.log(editCustomerPic.files);
        if ((editCustomer.customerpic === null) || (editCustomer.customerpic.files)) {

            const formData = new FormData();
            formData.append('file', editCustomer.customerpic.files[0]);
            await axiosClientUploads.post(`/api/uploads/penghuni`, formData,).then((res) => {
                //console.log(res.data.file.filename);
                let payload = {
                    "id": currentCustomer.id,
                    "firstname": editCustomer.firstname,
                    "lastname": editCustomer.lastname,
                    "gender": editCustomer.gender,
                    "address": editCustomer.address,
                    "provincies": editCustomer.provincies,
                    "regencies": editCustomer.regencies,
                    "districts": editCustomer.districts,
                    "villages": editCustomer.villages,
                    "nik": editCustomer.nik,
                    "emergencycontact": editCustomer.emergencycontact,
                    "emergencycontactname": editCustomer.emergencycontactname,
                    "customerpic": res.data.file.filename,
                    "mobilenumber": editCustomer.mobilenumber,
                    "password": editCustomer.password,
                    "roomid": editCustomer.roomid,
                    "contractdate": editCustomer.contractdate
                }
                axiosClient.put(`/api/penghuni/${currentCustomer.id}`, payload);
            }).catch((err) => {
                console.error(err);
                Swal.fire({
                    title: "Error!",
                    text: `${err}`,
                    icon: "error",
                    timer: 1500
                });
            });
            Swal.fire({
                title: "Berhasil Diubah!",
                text: `Data Penghuni atasnama ${editCustomer.firstname} berhasil diubah!`,
                icon: "success",
                timer: 1500
            });
        } else {
            try {
                let payload = {
                    "id": currentCustomer.id,
                    "firstname": editCustomer.firstname,
                    "lastname": editCustomer.lastname,
                    "gender": editCustomer.gender,
                    "address": editCustomer.address,
                    "provincies": editCustomer.provincies,
                    "regencies": editCustomer.regencies,
                    "districts": editCustomer.districts,
                    "villages": editCustomer.villages,
                    "nik": editCustomer.nik,
                    "emergencycontact": editCustomer.emergencycontact,
                    "emergencycontactname": editCustomer.emergencycontactname,
                    "customerpic": currentCustomer.customerpic,
                    "mobilenumber": editCustomer.mobilenumber,
                    "password": editCustomer.password,
                    "roomid": editCustomer.roomid,
                    "contractdate": editCustomer.contractdate
                }
                axiosClient.put(`/api/penghuni/${currentCustomer.id}`, payload);
                Swal.fire({
                    title: "Berhasil Diubah!",
                    text: `Data Penghuni atasnama ${editCustomer.firstname} berhasil diubah!`,
                    icon: "success",
                    timer: 1500
                });
            } catch (err) {
                Swal.fire({
                    title: "Error!",
                    text: `${err}`,
                    icon: "error",
                    timer: 1500
                });
            }

        }
        fetchCustomers();
    }

    const handleSubmitAdd = () => {
        postData();
        hideModalCustomerAdd();
    };

    const handleSubmitEdit = () => {
        updateData();
        hideModalCustomerEdit();

    };
    const fetchLastIdCustomers = async () => {
        try {
            const response = await axiosClient.get(`/api/penghuni/last`);
            setLastIdCustomer(response.data.data);
        } catch (err) {
            console.error("Error fetching CustomerId:", err);
            Swal.fire({
                title: "Error!",
                text: `${err}`,
                icon: "error",
                timer: 1500
            });
        }
    };

    const filterCustomers = (id) => {

        let filtered = customers_detail.filter((o) => {
            if (o.id === id) return o;
        })
        setCurrentCustomer(filtered[0]);
        setEditCustomer(filtered[0]);
        // console.log(editCustomerPlnId,editCustomerPlnNames);
    };

    const deleteCustomer = (id) => {
        Swal.fire({
            title: "Apakah Anda yakin?",
            text: "Jika dilakukan, data penghuni tidak bisa dipulihkan lagi!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "Batalkan",
            confirmButtonText: "Ya, Mengerti"
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/api/penghuni/${id}`)
                Swal.fire({
                    title: "Terhapus!",
                    text: "Penghuni berhasil dihapus!",
                    icon: "success",
                    timer: 1500
                });
            }
        });
        fetchCustomers();
    }
    const [rooms_detail, setRoomDetail] = useState([{}]);

    const fetchRooms = async () => {
        setRoomDetail([{}]);
        try {
            const response = await axiosClient.get(`/api/kamar/empty`);
            setRoomDetail(response.data.data);
        } catch (error) {
            console.error("Error fetching Rooms:", error);
        }
    };

    return (
        <div>
            {/* <CustomersTable roomData={rooms_detail} /> */}
            {/* {rooms_detail} */}

            <div>
                <div className="row">
                    <div className="col-md-12 grid-margin">
                        <div className="row">
                            <div className="col-12 col-xl-6 mb-4 mb-xl-0">
                                <h3 className="font-weight-bold">Daftar Penghuni</h3>
                            </div>
                            <div className="col-12 col-xl-6 mr-4">
                                <div className="justify-content-end d-flex">
                                    <button className="btn btn-md btn-primary" onClick={showModalCustomerAdd}><i className="mdi mdi mdi-home-plus-outline"></i> Tambah Penghuni</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="table-responsive table-bordered">
                            <table className="table table-bordered table-striped table-light">
                                <thead className="table-primary text-center">
                                    <tr>
                                        <th>Pengoperasian</th>
                                        <th>Nama Penghuni</th>
                                        <th>Jenis Kelamin</th>
                                        <th>Foto Penghuni</th>
                                        <th>Alamat</th>
                                        <th>Kamar</th>
                                        <th>Tanggal Kontrak</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {customers_detail ? customers_detail.map(customer => (

                                        <tr key={parseInt(customer.id)}>
                                            <td>
                                                <div className="button-group text-center">
                                                    <button className="btn btn-outline-warning btn-square btn-sm" onClick={() => showModalCustomerEdit(customer.id)}><span className="text-lg"><i className="mdi mdi-information-variant"></i> Info</span></button>
                                                    <button className="btn btn-outline-danger btn-square btn-sm" onClick={() => deleteCustomer(customer.id)}><span className="text-lg"><i className="mdi mdi-delete"></i> Hapus</span></button>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                {customer.firstname + " " + customer.lastname}
                                            </td>
                                            <td className="text-center">
                                                {customer.gender === 'M' ? 'Pria' : 'Wanita'}
                                            </td>
                                            <td className="text-center">
                                                <ModalImage
                                                    small={apiUrl + '/images/customer/' + customer.customerpic}
                                                    large={apiUrl + '/images/customer/' + customer.customerpic}
                                                    alt={customer.firstname + " " + customer.lastname}
                                                />
                                            </td>
                                            <td className="text-center">
                                                {customer.address}
                                            </td>
                                            <td className="text-center">
                                                {customer.roomname}
                                            </td>
                                            <td className="text-center">
                                                {customer.contractdate}
                                            </td>
                                        </tr>
                                    )) : (<tr className="bg-dark-gray" key="1">
                                        <td>
                                            <p className="placeholder-glow">
                                                <span className="placeholder col-12"></span>
                                            </p>
                                        </td>
                                        <td>
                                            <p className="placeholder-glow">
                                                <span className="placeholder col-12"></span>
                                            </p>
                                        </td>
                                        <td>
                                            <p className="placeholder-glow">
                                                <span className="placeholder col-12"></span>
                                            </p>
                                        </td>
                                        <td>
                                            <p className="placeholder-glow">
                                                <span className="placeholder col-12"></span>
                                            </p>
                                        </td>
                                        <td>
                                            <p className="placeholder-glow">
                                                <span className="placeholder col-12"></span>
                                            </p>
                                        </td>
                                        <td>
                                            <p className="placeholder-glow">
                                                <span className="placeholder col-12"></span>
                                            </p>
                                        </td>
                                    </tr>)}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="modal fade" ref={refModalCustomerAdd} tabIndex="-1" >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header bg-primary text-light">
                                <h5 className="modal-title"><i className="mdi mdi mdi-home-plus-outline"></i> Tambah Penghuni</h5>
                                <button type="button" className="btn btn-sm text-light" onClick={hideModalCustomerAdd}>X</button>
                            </div>
                            <div className="modal-body">
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="id-penghuni">ID Penghuni</span>
                                    <input type="text" className="form-control" disabled id="id-penghuni" aria-describedby="id-penghuni" onChange={(e) => setNewCustomerId(e.target.value)} value={lastIdCustomer}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="nama-depan-penghuni">Nama Depan</span>
                                    <input type="text" className="form-control" id="nama-depan-penghuni" aria-describedby="nama-depan-penghuni" placeholder="Nama Depan Penghuni" onChange={(e) => setNewCustomer({ ...newCustomer,  ...newCustomer, firstname: e.target.value})} value={newCustomer.firstname}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="nama-belakang-penghuni">Nama Belakang</span>
                                    <input type="text" className="form-control" id="nama-belakang-penghuni" aria-describedby="nama-belakang-penghuni" placeholder="Nama Belakang Penghuni" onChange={(e) => setNewCustomer({ ...newCustomer, lastname: e.target.value})} value={newCustomer.lastname}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="gender-penghuni">Jenis Kelamin</span>
                                    <select className="form-select text-dark" id="gender-penghuni" aria-describedby="gender-penghuni" onChange={(e) => setNewCustomer({ ...newCustomer, gender: e.target.value})} value={newCustomer.gender}>
                                        <option value="" disabled selected="selected">--- Pilih Jenis Kelamin ---</option>
                                        <option value="M">Pria</option>
                                        <option value="F">Wanita</option>
                                    </select>
                                </div>
                                <div className="input-group input-group-sm mb-3"> 
                                    <span className="input-group-text" id="foto-penghuni">Foto Penghuni</span>
                                    <input type="file" className="form-control" id="foto-penghuni" aria-describedby="foto-penghuni" aria-label="Foto Penghuni" onChange={(e) => setNewCustomer({ ...newCustomer,  customerpic: e.target })} value={newCustomer.customerpic ? newCustomer.customerpic.value : newCustomer.customerpic}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="alamat-penghuni">Alamat</span>
                                    <textarea row="4" className="form-control" id="alamat-penghuni" aria-describedby="alamat-penghuni" placeholder="Alamat Penghuni" onChange={(e) => setNewCustomer({ ...newCustomer,  address: e.target.value })} value={newCustomer.address}></textarea>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="kamar-penghuni">Pilih Kamar</span>
                                    <select className="form-select text-dark" id="kamar-penghuni" aria-describedby="kamar-penghuni" onChange={(e) => setNewCustomer({ ...newCustomer,  roomid: e.target.value })} value={newCustomer.roomid}>
                                        <option value="" disabled selected="selected">--- Pilih Kamar ---</option>
                                        {rooms_detail.map(room=>(
                                            <option key={room.id} value={room.id}>{room.roomname}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="kontrak-penghuni">Tanggal Kontrak</span>
                                    <input type="date" className="form-control" id="kontrak-penghuni" aria-describedby="kontrak-penghuni" placeholder="Tanggal Kontrak" onChange={(e) => setNewCustomer({ ...newCustomer, contractdate: e.target.value })} value={newCustomer.contractdate}></input>
                                </div> 

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger text-light" onClick={hideModalCustomerAdd}>Batal</button>
                                <button type="submit" onClick={handleSubmitAdd} className="btn btn-primary">Tambah</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" ref={refModalCustomerEdit} tabIndex="-1" >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header bg-primary text-light">
                                <h5 className="modal-title"><i className="mdi mdi mdi-home-plus-outline"></i> Ubah Penghuni</h5>
                                <button type="button" className="btn btn-sm text-light" onClick={hideModalCustomerEdit}>X</button>
                            </div>
                            <div className="modal-body">
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="id-penghuni">ID Penghuni</span>
                                    <input type="text" className="form-control" disabled id="id-penghuni" aria-describedby="id-penghuni" onChange={(e) => setEditCustomer({ ...editCustomer, id: e.target.value })} value={editCustomer.id}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="nama-depan-penghuni">Nama Depan</span>
                                    <input type="text" className="form-control" id="nama-depan-penghuni" aria-describedby="nama-depan-penghuni" placeholder="Nama Depan Penghuni" onChange={(e) => setEditCustomer({ ...editCustomer, firstname: e.target.value })} value={editCustomer.firstname}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="nama-belakang-penghuni">Nama Belakang</span>
                                    <input type="text" className="form-control" id="nama-belakang-penghuni" aria-describedby="nama-belakang-penghuni" placeholder="Nama Belakang Penghuni" onChange={(e) => setEditCustomer({ ...editCustomer, lastname: e.target.value })} value={editCustomer.lastname}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="gender-penghuni">Jenis Kelamin</span>
                                    <select className="form-select text-dark" id="gender-penghuni" aria-describedby="gender-penghuni" onChange={(e) => setEditCustomer({ ...editCustomer, gender: e.target.value })} value={editCustomer.gender}>
                                        <option value="" disabled selected="selected">--- Pilih Jenis Kelamin ---</option>
                                        <option value="M">Pria</option>
                                        <option value="F">Wanita</option>
                                    </select>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="foto-penghuni">Foto Penghuni</span>
                                    <input type="file" className="form-control" id="foto-penghuni" aria-describedby="foto-penghuni" aria-label="Foto Penghuni" onChange={(e) => setEditCustomer({ ...editCustomer, customerpic: e.target })} value={editCustomer.customerpic ? editCustomer.customerpic.value : editCustomer.customerpic}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="alamat-penghuni">Alamat</span>
                                    <textarea row="4" className="form-control" id="alamat-penghuni" aria-describedby="alamat-penghuni" placeholder="Alamat Penghuni" onChange={(e) => setEditCustomer({ ...editCustomer, address: e.target.value })} value={editCustomer.address}></textarea>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="kamar-penghuni">Pilih Kamar</span>
                                    <select className="form-select text-dark" id="kamar-penghuni" aria-describedby="kamar-penghuni" onChange={(e) => setEditCustomer({ ...editCustomer, roomid: e.target.value })} value={editCustomer.roomid}>
                                        <option value="" disabled selected="selected">--- Pilih Kamar ---</option>
                                        {rooms_detail.map(room => (
                                            <option key={room.id} value={room.id}>{room.roomname}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="kontrak-penghuni">Tanggal Kontrak</span>
                                    <input type="date" className="form-control" id="kontrak-penghuni" aria-describedby="kontrak-penghuni" placeholder="Tanggal Kontrak" onChange={(e) => setNewCustomer({ ...newCustomer, contractdate: e.target.value })} value={newCustomer.contractdate}></input>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger text-light" onClick={hideModalCustomerEdit}>Batal</button>
                                <button type="submit" onClick={handleSubmitEdit} className="btn btn-primary">Ubah</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div>

    );
}
export default Penghuni;
