
import React, { useRef, useState, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import { Modal } from "bootstrap";
import { axiosClient, axiosClientUploads } from 'api/axiosClient';
import { apiUrl } from 'config';
import ModalImage from "react-modal-image";
import Swal from 'sweetalert2'


function Kamar() {
    const [rooms_detail, setRoomDetail] = useState([{}]);

    const fetchRooms = async () => {
        setRoomDetail([{}]);
        try {
            const response = await axiosClient.get(`/api/kamar`);
            setRoomDetail(response.data.data);
        } catch (error) {
            console.error("Error fetching Rooms:", error);
        }
    };
    useEffect(() => {
        fetchRooms();

    }, []);
    const [newRoomId, setNewRoomId] = useState('');
    const [newRoomName, setNewRoomName] = useState('');
    const [newRoomClass, setNewRoomClass] = useState('');
    const [newRoomPic, setNewRoomPic] = useState('');
    const [newRoomPlnId, setNewRoomPlnId] = useState('');
    const [newRoomPlnNames, setNewRoomPlnNames] = useState('');
    const [editRoomId, setEditRoomId] = useState('');
    const [editRoomName, setEditRoomName] = useState('');
    const [editRoomClass, setEditRoomClass] = useState('');
    const [editRoomPic, setEditRoomPic] = useState('');
    const [editRoomPlnId, setEditRoomPlnId] = useState('');
    const [editRoomPlnNames, setEditRoomPlnNames] = useState('');
    const [lastIdRoom, setLastIdRoom] = useState('');
    const [currentRoom, setCurrentRoom] = useState({});
    // const [roomFacility, setRoomFacility] = useState({ });
    const refModalRoomAdd = useRef()
    const refModalRoomEdit = useRef()
    // const { roomData } = props;

    // s1 = JSON.parse(JSON.stringify(roomData.facilities));
    // roomFacility = eval('(' + s1 + ')');


    const showModalRoomAdd = () => {
        const modalEle = refModalRoomAdd.current
        const bsModal = new Modal(modalEle, {
            backdrop: 'static',
            keyboard: false
        })
        fetchLastIdRooms();
        bsModal.show()
    }

    const hideModalRoomAdd = () => {
        const modalEle = refModalRoomAdd.current
        const bsModal = Modal.getInstance(modalEle)
        initData();
        //navigate("/kamar")
        bsModal.hide()
    }

    const initData = () => {
        setCurrentRoom({});
        setEditRoomId("");
        setEditRoomName("");
        setEditRoomClass("");
        setEditRoomPic("");
        setEditRoomPlnId("");
        setEditRoomPlnNames("");
        setNewRoomId("");
        setNewRoomName("");
        setNewRoomClass("");
        setNewRoomPic("");
        setNewRoomPlnId("");
        setNewRoomPlnNames("");
        setLastIdRoom("");
        // console.log("initial");
    }

    const showModalRoomEdit = (id) => {
        const modalEle = refModalRoomEdit.current
        const bsModal = new Modal(modalEle, {
            backdrop: 'static',
            keyboard: false
        })
        filterRooms(id);
        bsModal.show()
    }

    const hideModalRoomEdit = () => {
        const modalEle = refModalRoomEdit.current
        const bsModal = Modal.getInstance(modalEle)
        initData();
        //navigate("/kamar")
        bsModal.hide()
    }

    const postData = async () => {
        const formData = new FormData();
        formData.append('file', newRoomPic.files[0]);

        try {
            await axiosClientUploads.post(`/api/uploads/kamar`, formData,).then((res) => {
                //console.log(res.data.file.filename);
                let payload = {
                    "roomid": lastIdRoom,
                    "roomname": newRoomName,
                    "roomclass": newRoomClass,
                    "roompic": res.data.file.filename,
                    "plnid": newRoomPlnId,
                    "plnnames": newRoomPlnNames
                }
                axiosClient.post(`/api/kamar`, payload);
            }).catch((err) => {
                console.error(err);
                Swal.fire({
                    title: "Error!",
                    text: `${err}`,
                    icon: "error",
                    timer: 1500
                });
            });
            Swal.fire({
                title: "Berhasil Ditambah!",
                text: `Data Kamar ${editRoomName} berhasil ditambahkan!`,
                icon: "success",
                timer: 1500
            });
        } catch (error) {
            console.error("Error fetching RoomId:", error);
        }
        fetchRooms();
    }

    const updateData = async () => {
        // console.log(editRoomPic.files);
        if ((editRoomPic === null) || (editRoomPic.files)) {

            const formData = new FormData();
            formData.append('file', editRoomPic.files[0]);
            await axiosClientUploads.post(`/api/uploads/kamar`, formData,).then((res) => {
                //console.log(res.data.file.filename);
                let payload = {
                    "roomid": currentRoom.roomid,
                    "roomname": editRoomName,
                    "roomclass": editRoomClass,
                    "roompic": res.data.file.filename,
                    "plnid": editRoomPlnId,
                    "plnnames": editRoomPlnNames
                }
                axiosClient.put(`/api/kamar/${currentRoom.roomid}`, payload);
                Swal.fire({
                    title: "Berhasil Diubah!",
                    text: `Data Kamar ${editRoomName} berhasil diubah!`,
                    icon: "success",
                    timer: 1500
                });
            }).catch((err) => {
                console.error(err);
                Swal.fire({
                    title: "Error!",
                    text: `${err}`,
                    icon: "error",
                    timer: 1500
                });
            });
        } else {
            try {
                let payload = {
                    "roomid": currentRoom.roomid,
                    "roomname": editRoomName,
                    "roomclass": editRoomClass,
                    "roompic": currentRoom.roompic,
                    "plnid": editRoomPlnId,
                    "plnnames": editRoomPlnNames
                }
                //console.log(payload);
                axiosClient.put(`/api/kamar/${currentRoom.roomid}`, payload);
                Swal.fire({
                    title: "Berhasil Diubah!",
                    text: `Data Kamar ${editRoomName} berhasil diubah!`,
                    icon: "success",
                    timer: 1500
                });
            } catch (err) {
                Swal.fire({
                    title: "Error!",
                    text: `${err}`,
                    icon: "error",
                    timer: 1500
                });
            }
            
        }
        fetchRooms();
    }

    const handleSubmitAdd = () => {
        postData();
        hideModalRoomAdd();
    };

    const handleSubmitEdit = () => {
        updateData();
        hideModalRoomEdit();

    };
    const fetchLastIdRooms = async () => {
        try {
            const response = await axiosClient.get(`/api/kamar/last`);
            setLastIdRoom(response.data.data);
        } catch (err) {
            console.error("Error fetching RoomId:", err);
            Swal.fire({
                title: "Error!",
                text: `${err}`,
                icon: "error",
                timer: 1500
            });
        }
    };

    const filterRooms = (id) => {

        let filtered = rooms_detail.filter((o) => {
            if (o.id === id) return o;
        })
        setCurrentRoom(filtered[0]);
        setEditRoomId(filtered[0].roomid);
        setEditRoomName(filtered[0].roomname);
        setEditRoomClass(filtered[0].roomclass);
        setEditRoomPic(filtered[0].roompic);
        setEditRoomPlnId(filtered[0].plnid);
        setEditRoomPlnNames(filtered[0].plnnames);
        // console.log(editRoomPlnId,editRoomPlnNames);
    };

    const deleteRoom = (id) => {
        Swal.fire({
            title: "Apakah Anda yakin?",
            text: "Jika dilakukan, data kamar tidak bisa dipulihkan lagi!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "Batalkan",
            confirmButtonText: "Ya, Mengerti"
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/api/kamar/${id}`)
                Swal.fire({
                    title: "Terhapus!",
                    text: "Kamar berhasil dihapus!",
                    icon: "success",
                    timer: 1500
                });
            }
        });
        fetchRooms();
    }

    return (
        <div>
            {/* <RoomsTable roomData={rooms_detail} /> */}
            {/* {rooms_detail} */}
           
            <div>
                <div className="row">
                    <div className="col-md-12 grid-margin">
                        <div className="row">
                            <div className="col-12 col-xl-6 mb-4 mb-xl-0">
                                <h3 className="font-weight-bold">Daftar Kamar</h3>
                            </div>
                            <div className="col-12 col-xl-6 mr-4">
                                <div className="justify-content-end d-flex">
                                    <button className="btn btn-md btn-primary" onClick={showModalRoomAdd}><i className="mdi mdi mdi-home-plus-outline"></i> Tambah Kamar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="table-responsive table-bordered">
                            <table className="table table-bordered table-striped table-light">
                                <thead className="table-primary text-center">
                                    <tr>
                                        <th>Pengoperasian</th>
                                        <th>Nama Kamar</th>
                                        <th>Foto Kamar</th>
                                        <th>Kelas</th>
                                        <th>Harga Sewa</th>
                                        <th>Fasilitas</th>
                                        <th>Nama Penghuni</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {rooms_detail ? rooms_detail.map(room => (

                                        <tr key={parseInt(room.id)}>
                                            <td>
                                                <div className="button-group text-center">
                                                    <button className="btn btn-outline-warning btn-square btn-sm" onClick={() => showModalRoomEdit(room.id)}><span className="text-lg"><i className="mdi mdi-information-variant"></i> Info</span></button>
                                                    <button className="btn btn-outline-danger btn-square btn-sm" onClick={() => deleteRoom(room.id)} ><span className="text-lg"><i className="mdi mdi-delete"></i> Hapus</span></button>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                {room.roomname}
                                            </td>
                                            <td className="text-center">
                                                {/* <div id="lightgallery" class="lightGallery">
                                                <a href={apiUrl + '/images/room/' + room.roompic} class="image-tile"> <img src={apiUrl + '/images/room/' + room.roompic} alt={room.roomname} /></a>
                                            </div> */}
                                                {/* <img src={apiUrl + '/images/room/' + room.roompic} alt={room.roomname} /> */}
                                                <ModalImage
                                                    small={apiUrl + '/images/room/' + room.roompic}
                                                    large={apiUrl + '/images/room/' + room.roompic}
                                                    alt={room.roomname}
                                                />
                                            </td>
                                            <td className="text-center">
                                                {room.roomclass}
                                            </td>
                                            <td className="text-end">
                                                <NumericFormat value={room.roomprices} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
                                            </td>
                                            <td className="text-center">
                                                <span className="badge bg-primary"><i className="mdi mdi-square"></i>{room.facilities ? room.facilities.luas : ''}</span>
                                                <span className="badge bg-primary">{(room.facilities ? room.facilities.ac : '') ? <i className="mdi mdi-air-filter"></i> : ''}</span>
                                                <span className="badge bg-primary">{(room.facilities ? room.facilities.wifi : '') ? <i className="mdi mdi-wifi"></i> : ''}</span>
                                                <span className="badge bg-primary">{(room.facilities ? room.facilities.laundry : '') ? <i className="mdi mdi-washing-machine"></i> : ''}</span>
                                                <span className="badge bg-primary">{(room.facilities ? room.facilities.room_cleaning : '') ? <i className="mdi mdi-vacuum"></i> : ''}</span>
                                                <span className="badge bg-primary">{(room.facilities ? room.facilities.water_heater : '') ? <i className="mdi mdi-water-boiler"></i> : ''}</span>
                                            </td>
                                            <td className="text-center">
                                                {room.firstname === null ? null : room.firstname + " " + room.lastname}
                                            </td>
                                        </tr>
                                    )) : (<tr className="bg-dark-gray" key="1">
                                        <td>
                                            <p className="placeholder-glow">
                                                <span className="placeholder col-12"></span>
                                            </p>
                                        </td>
                                        <td>
                                            <p className="placeholder-glow">
                                                <span className="placeholder col-12"></span>
                                            </p>
                                        </td>
                                        <td>
                                            <p className="placeholder-glow">
                                                <span className="placeholder col-12"></span>
                                            </p>
                                        </td>
                                        <td>
                                            <p className="placeholder-glow">
                                                <span className="placeholder col-12"></span>
                                            </p>
                                        </td>
                                        <td>
                                            <p className="placeholder-glow">
                                                <span className="placeholder col-12"></span>
                                            </p>
                                        </td>
                                        <td>
                                            <p className="placeholder-glow">
                                                <span className="placeholder col-12"></span>
                                            </p>
                                        </td>
                                    </tr>)}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="modal fade" ref={refModalRoomAdd} tabIndex="-1" >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header bg-primary text-light">
                                <h5 className="modal-title"><i className="mdi mdi mdi-home-plus-outline"></i> Tambah Kamar</h5>
                                <button type="button" className="btn btn-sm text-light" onClick={hideModalRoomAdd}>X</button>
                            </div>
                            <div className="modal-body">
                                {/*id	kostid	roomid	roomname	roomclass	roomprices	facilities	roompic	plnid	plnnames	createdat	createdby	changedat	changeby*/}
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="id-kamar">ID Kamar</span>
                                    <input type="text" className="form-control" disabled id="id-kamar" aria-describedby="id-kamar" onChange={(e) => setNewRoomId(e.target.value)} value={lastIdRoom}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="nama-kamar">Nama Kamar</span>
                                    <input type="text" className="form-control" id="nama-kamar" aria-describedby="nama-kamar" placeholder="Nama Kamar" onChange={(e) => setNewRoomName(e.target.value)} value={newRoomName}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="kelas-kamar">Kelas Kamar</span>
                                    <select className="form-select text-dark" id="kelas-kamar" aria-describedby="kelas-kamar" onChange={(e) => setNewRoomClass(e.target.value)} value={newRoomClass}>
                                        <option value="" disabled selected="selected">--- Pilih Kelas ---</option>
                                        <option value="A">A</option>
                                        <option value="B">B</option>
                                        <option value="VIP">VIP</option>
                                    </select>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="foto-kamar">Foto Kamar</span>
                                    <input type="file" className="form-control" id="foto-kamar" aria-describedby="foto-kamar" aria-label="Foto Kamar" onChange={(e) => setNewRoomPic(e.target)} value={newRoomPic ? newRoomPic.value : newRoomPic}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="plnid-kamar">ID Pelanggan PLN</span>
                                    <input type="text" className="form-control" id="plnid-kamar" aria-describedby="plnid-kamar" placeholder="ID Pelanggan PLN" onChange={(e) => setNewRoomPlnId(e.target.value)} value={newRoomPlnId}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="harga-kamar">Nama Pelanggan PLN</span>
                                    <input type="text" className="form-control" id="plnnames-kamar" aria-describedby="plnnames-kamar" placeholder="Nama Pelanggan PLN" onChange={(e) => setNewRoomPlnNames(e.target.value)} value={newRoomPlnNames}></input>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger text-light" onClick={hideModalRoomAdd}>Batal</button>
                                <button type="submit" onClick={handleSubmitAdd} disabled={lastIdRoom && newRoomName && newRoomClass && newRoomPic && newRoomPlnId && newRoomPlnNames ? '' : 'disabled'} className="btn btn-primary">Tambah</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" ref={refModalRoomEdit} tabIndex="-1" >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header bg-primary text-light">
                                <h5 className="modal-title"><i className="mdi mdi mdi-home-plus-outline"></i> Ubah Kamar</h5>
                                <button type="button" className="btn btn-sm text-light" onClick={hideModalRoomEdit}>X</button>
                            </div>
                            <div className="modal-body">
                                {/*id	kostid	roomid	roomname	roomclass	roomprices	facilities	roompic	plnid	plnnames	createdat	createdby	changedat	changeby*/}
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="id-kamar">ID Kamar</span>
                                    <input type="text" className="form-control" disabled id="id-kamar" aria-describedby="id-kamar" onChange={(e) => setEditRoomId(e.target.value)} value={editRoomId}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="nama-kamar">Nama Kamar</span>
                                    <input type="text" className="form-control" id="nama-kamar" aria-describedby="nama-kamar" placeholder="Nama Kamar" onChange={(e) => setEditRoomName(e.target.value)} value={editRoomName}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="kelas-kamar">Kelas Kamar</span>
                                    <select className="form-select" id="kelas-kamar" aria-describedby="kelas-kamar" onChange={(e) => setEditRoomClass(e.target.value)} value={editRoomClass}>
                                        <option value="" disabled>--- Pilih Kelas ---</option>
                                        <option value="A" selected={currentRoom.roomclass === 'A' ? 'selected' : ''}>A</option>
                                        <option value="B" selected={currentRoom.roomclass === 'B' ? 'selected' : ''}>B</option>
                                        <option value="VIP" selected={currentRoom.roomclass === 'VIP' ? 'selected' : ''}>VIP</option>
                                    </select>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="foto-kamar">Foto Kamar</span>
                                    <input type="file" className="form-control" id="foto-kamar" aria-describedby="foto-kamar" aria-label="Foto Kamar" onChange={(e) => setEditRoomPic(e.target)} value={editRoomPic ? editRoomPic.value : editRoomPic}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="plnid-kamar">ID Pelanggan PLN</span>
                                    <input type="text" className="form-control" id="plnid-kamar" aria-describedby="plnid-kamar" placeholder="ID Pelanggan PLN" onChange={(e) => setEditRoomPlnId(e.target.value)} value={editRoomPlnId}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="harga-kamar">Nama Pelanggan PLN</span>
                                    <input type="text" className="form-control" id="plnnames-kamar" aria-describedby="plnnames-kamar" placeholder="Nama Pelanggan PLN" onChange={(e) => setEditRoomPlnNames(e.target.value)} value={editRoomPlnNames}></input>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger text-light" onClick={hideModalRoomEdit}>Batal</button>
                                <button type="submit" onClick={handleSubmitEdit} className="btn btn-primary">Ubah</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div>

    );
}
export default Kamar;
