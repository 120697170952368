// SideBar.js

import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const Sidebar = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  return (
    <nav className="sidebar bg-light sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        <li className={currentPath === '/beranda' ? 'nav-item active' : 'nav-item'}>
          <NavLink to="/beranda" className="nav-link">
            <i className="mdi mdi-view-dashboard menu-icon"></i>
            <span className="menu-title">Beranda</span>
          </NavLink>
        </li>
        <li className={currentPath === '/kamar' ? 'nav-item active' : 'nav-item'}>
          <NavLink to="/kamar" className="nav-link">
            <i className="mdi mdi-home-group menu-icon"></i>
            <span className="menu-title">Kamar</span>
          </NavLink>
        </li>

        <li className={currentPath === '/penghuni' || currentPath === '/penghuni/pembayaran' || currentPath === '/penghuni/listkomplain' ? 'nav-item active' : 'nav-item'}>
          <a
            className="nav-link"
            data-bs-toggle="collapse"
            href="#penghuni"
            aria-expanded="false"
            aria-controls="penghuni"
          >
            <i className="mdi mdi-account-group menu-icon"></i>
            <span className="menu-title">Penghuni</span>
            <i className="menu-arrow"></i>
          </a>
          <div className="collapse" id="penghuni">
            <ul className="nav flex-column sub-menu">
              <li className={currentPath === '/penghuni' ? 'nav-item active' : 'nav-item'}><NavLink to="/penghuni" className="nav-link">Daftar Penghuni</NavLink>
              </li>
              <li className={currentPath === '/penghuni/pembayaran' ? 'nav-item active' : 'nav-item'}><NavLink to="/penghuni/pembayaran" className="nav-link">Pembayaran</NavLink>
              </li>
              <li className={currentPath === '/penghuni/listkomplain' ? 'nav-item active' : 'nav-item'}><NavLink to="/penghuni/listkomplain" className="nav-link">List Komplain</NavLink>
              </li>
            </ul>
          </div>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            data-bs-toggle="collapse"
            href="#karyawan"
            aria-expanded="false"
            aria-controls="karyawan"
          >
            <i className="mdi mdi-account-tie menu-icon"></i>
            <span className="menu-title">Karyawan</span>
            <i className="menu-arrow"></i>
          </a>
          <div className="collapse" id="karyawan">
            <ul className="nav flex-column sub-menu">
              <li className="nav-item"><NavLink to="/karyawan" className="nav-link">Daftar Karyawan</NavLink>
              </li>
              <li className="nav-item"><NavLink to="/karyawan/presensi" className="nav-link">Presensi</NavLink>
              </li>
              <li className="nav-item"><NavLink to="/karyawan/cashflow" className="nav-link">Cashflow</NavLink>
              </li>
              <li className="nav-item"><NavLink to="/karyawan/laporan/presensi" className="nav-link">Laporan Presensi</NavLink>
              </li>
              <li className="nav-item"><NavLink to="/karyawan/laporan/cashflow" className="nav-link">Laporan Cashflow</NavLink>
              </li>
              <li className="nav-item"><NavLink to="/karyawan/komplain" className="nav-link">List Komplain Penghuni</NavLink>
              </li>
              <li className="nav-item"><NavLink to="/karyawan/log/keamanan" className="nav-link">Log Petugas Keamanan</NavLink>
              </li>
              <li className="nav-item"><NavLink to="/karyawan/log/kebersihan" className="nav-link">Log Petugas Kebersihan</NavLink>
              </li>
              <li className="nav-item"><NavLink to="/karyawan/log/dapur" className="nav-link">Log Petugas Dapur</NavLink>
              </li>
              <li className="nav-item"><NavLink to="/karyawan/log/laundry" className="nav-link">Log Petugas Laundry</NavLink>
              </li>
            </ul>
          </div>
        </li>
        <li className={currentPath === '/pengguna' || currentPath === '/profil' ? 'nav-item active' : 'nav-item'}>
          <a
            className="nav-link"
            data-bs-toggle="collapse"
            href="#pengguna"
            aria-expanded="false"
            aria-controls="pengguna"
          >
            <i className="mdi mdi-account-supervisor menu-icon"></i>
            <span className="menu-title">Pengguna</span>
            <i className="menu-arrow"></i>
          </a>
          <div className="collapse" id="pengguna">
            <ul className="nav flex-column sub-menu">
              <li className={currentPath === '/pengguna' ? 'nav-item active' : 'nav-item'}><NavLink to="/pengguna" className="nav-link">Daftar Pengguna</NavLink>
              </li>
              <li className={currentPath === '/profil' ? 'nav-item active' : 'nav-item'}><NavLink to="/profil" className="nav-link">Profil Pengguna</NavLink>
              </li>
            </ul>
          </div>
        </li>

      </ul>
    </nav>
  );
}
export default Sidebar;