
import React, { useRef, useState, useEffect } from 'react';
import { Modal } from "bootstrap";
import { axiosClient, axiosClientUploads } from 'api/axiosClient';
import { apiUrl } from 'config';
import ModalImage from "react-modal-image";
import Swal from 'sweetalert2'


function Karyawan() {
    const [crews_detail, setCrewDetail] = useState([{}]);

    const fetchCrews = async () => {
        setCrewDetail([{}]);
        try {
            const response = await axiosClient.get(`/crews`);
            setCrewDetail(response.data.data);
        } catch (error) {
            console.error("Error fetching Crews:", error);
        }
    };
    useEffect(() => {
        fetchCrews();

    }, []);

    const [newCrew, setNewCrew] = useState({
        firstname: null,
        lastname: null,
        username: null,
        password: null,
        role: null
    });
    const [newCrewId, setNewCrewId] = useState('');

    const [editCrew, setEditCrew] = useState({
        firstname: null,
        lastname: null,
        username: null,
        password: null,
        role: null
    });
    const [editCrewId, setEditCrewId] = useState('');

    const [lastIdCrew, setLastIdCrew] = useState('');
    const [currentCrew, setCurrentCrew] = useState({});
    const refModalCrewAdd = useRef()
    const refModalCrewEdit = useRef()

    const showModalCrewAdd = () => {
        const modalEle = refModalCrewAdd.current
        const bsModal = new Modal(modalEle, {
            backdrop: 'static',
            keyboard: false
        })
        fetchLastIdCrews();
        bsModal.show()
    }

    const hideModalCrewAdd = () => {
        const modalEle = refModalCrewAdd.current
        const bsModal = Modal.getInstance(modalEle)

        initData();
        bsModal.hide()
    }

    const initData = () => {
        setCurrentCrew({});
        setNewCrew({
            firstname: null,
            lastname: null,
            username: null,
            password: null,
            role: null
        });
        setEditCrew({
            firstname: null,
            lastname: null,
            username: null,
            password: null,
            role: null
        });
        setLastIdCrew("");
        // console.log("initial");
    }

    const showModalCrewEdit = (id) => {
        const modalEle = refModalCrewEdit.current
        const bsModal = new Modal(modalEle, {
            backdrop: 'static',
            keyboard: false
        });
        filterCrews(id);
        bsModal.show()
    }

    const hideModalCrewEdit = () => {
        const modalEle = refModalCrewEdit.current
        const bsModal = Modal.getInstance(modalEle)
        initData();
        //navigate("/pengguna")
        bsModal.hide()
    }

    const postData = async () => {
        const formData = new FormData();
        formData.append('file', newCrew.customerpic.files[0]);

        try {

            let payload = {
                "firstname": newCrew.firstname,
                "lastname": newCrew.lastname,
                "username": newCrew.username,
                "password": newCrew.password,
                "role": newCrew.role
            }
            axiosClient.post(`/crews`, payload);

            Swal.fire({
                title: "Berhasil Ditambah!",
                text: `Data Pengguna atasnama ${editCrew.firstname} berhasil ditambahkan!`,
                icon: "success",
                timer: 1500
            });
        } catch (error) {
            console.error("Error fetching CrewId:", error);
        }
        fetchCrews();
    }

    const updateData = async () => {

        let payload = {
            "firstname": editCrew.firstname,
            "lastname": editCrew.lastname,
            "username": editCrew.username,
            "password": editCrew.password,
            "role": editCrew.role
        }
        axiosClient.put(`/crews/${editCrew.id}`, payload);

        Swal.fire({
            title: "Berhasil Diubah!",
            text: `Data Pengguna atasnama ${editCrew.firstname} berhasil diubah!`,
            icon: "success",
            timer: 1500
        });

        fetchCrews();
    }

    const handleSubmitAdd = () => {
        postData();
        hideModalCrewAdd();
    };

    const handleSubmitEdit = () => {
        updateData();
        hideModalCrewEdit();

    };
    const fetchLastIdCrews = async () => {
        try {
            const response = await axiosClient.get(`/crews/last`);
            setLastIdCrew(response.data.data);
        } catch (err) {
            console.error("Error fetching CrewId:", err);
            Swal.fire({
                title: "Error!",
                text: `${err}`,
                icon: "error",
                timer: 1500
            });
        }
    };

    const filterCrews = (id) => {

        let filtered = crews_detail.filter((o) => {
            if (o.id === id) return o;
        })
        setCurrentCrew(filtered[0]);
        setEditCrew(filtered[0]);
        // console.log(editCrewPlnId,editCrewPlnNames);
    };

    const deleteCrew = (id) => {
        Swal.fire({
            title: "Apakah Anda yakin?",
            text: "Jika dilakukan, data pengguna tidak bisa dipulihkan lagi!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "Batalkan",
            confirmButtonText: "Ya, Mengerti"
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete(`/crews/${id}`)
                Swal.fire({
                    title: "Terhapus!",
                    text: "Pengguna berhasil dihapus!",
                    icon: "success",
                    timer: 1500
                });
            }
        });
        fetchCrews();
    }

    return (
        <div>
            <div>
                <div className="row">
                    <div className="col-md-12 grid-margin">
                        <div className="row">
                            <div className="col-12 col-xl-6 mb-4 mb-xl-0">
                                <h3 className="font-weight-bold">Daftar Pengguna</h3>
                            </div>
                            <div className="col-12 col-xl-6 mr-4">
                                <div className="justify-content-end d-flex">
                                    <button className="btn btn-md btn-primary" onClick={showModalCrewAdd}><i className="mdi mdi mdi-home-plus-outline"></i> Tambah Pengguna</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="table-responsive table-bordered">
                            <table className="table table-bordered table-striped table-light">
                                <thead className="table-primary text-center">
                                    <tr>
                                        <th>Pengoperasian</th>
                                        <th>Nama Crew</th>
                                        <th>Crewname</th>
                                        <th>Hak Akses</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {crews_detail ? crews_detail.map(user => (

                                        <tr key={parseInt(user.id)}>
                                            <td>
                                                <div className="button-group text-center">
                                                    <button className="btn btn-outline-warning btn-square btn-sm" onClick={() => showModalCrewEdit(user.id)}><span className="text-lg"><i className="mdi mdi-information-variant"></i> Info</span></button>
                                                    <button className="btn btn-outline-danger btn-square btn-sm" onClick={() => deleteCrew(user.id)}><span className="text-lg"><i className="mdi mdi-delete"></i> Hapus</span></button>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                {user.firstname + " " + user.lastname}
                                            </td>
                                            <td className="text-center">
                                                {user.username}
                                            </td>
                                            <td className="text-center">
                                                {user.rolename}
                                            </td>

                                        </tr>
                                    )) : (<tr className="bg-dark-gray" key="1">
                                        <td>
                                            <p className="placeholder-glow">
                                                <span className="placeholder col-12"></span>
                                            </p>
                                        </td>
                                        <td>
                                            <p className="placeholder-glow">
                                                <span className="placeholder col-12"></span>
                                            </p>
                                        </td>
                                        <td>
                                            <p className="placeholder-glow">
                                                <span className="placeholder col-12"></span>
                                            </p>
                                        </td>
                                        <td>
                                            <p className="placeholder-glow">
                                                <span className="placeholder col-12"></span>
                                            </p>
                                        </td>

                                    </tr>)}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="modal fade" ref={refModalCrewAdd} tabIndex="-1" >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header bg-primary text-light">
                                <h5 className="modal-title"><i className="mdi mdi mdi-home-plus-outline"></i> Tambah Pengguna</h5>
                                <button type="button" className="btn btn-sm text-light" onClick={hideModalCrewAdd}>X</button>
                            </div>
                            <div className="modal-body">
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="id-pengguna">ID Pengguna</span>
                                    <input type="text" className="form-control" disabled id="id-pengguna" aria-describedby="id-pengguna" onChange={(e) => setNewCrewId(e.target.value)} value={lastIdCrew}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="nama-depan-pengguna">Nama Depan</span>
                                    <input type="text" className="form-control" id="nama-depan-pengguna" aria-describedby="nama-depan-pengguna" placeholder="Nama Depan Pengguna" onChange={(e) => setNewCrew({ ...newCrew, firstname: e.target.value })} value={newCrew.firstname}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="nama-belakang-pengguna">Nama Belakang</span>
                                    <input type="text" className="form-control" id="nama-belakang-pengguna" aria-describedby="nama-belakang-pengguna" placeholder="Nama Belakang Pengguna" onChange={(e) => setNewCrew({ ...newCrew, lastname: e.target.value })} value={newCrew.lastname}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="username">Crewname</span>
                                    <input type="text" className="form-control" id="username" aria-describedby="username" placeholder="Crewname" onChange={(e) => setNewCrew({ ...newCrew, username: e.target.value })} value={newCrew.username}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="password">Password</span>
                                    <input type="password" className="form-control" id="password" aria-describedby="password" placeholder="Password" onChange={(e) => setNewCrew({ ...newCrew, password: e.target.value })} value={newCrew.password}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="confirm-password">Konfirmasi Password</span>
                                    <input type="password" className="form-control" id="confirm-password" aria-describedby="confirm-password" placeholder="Confirm Password" onChange={(e) => e.target.value.length > 3 && e.target.value === newCrew.password ? e.target.className = "form-control bg-success" : e.target.className = "form-control bg-danger"}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="role">Hak Akses</span>
                                    <select className="form-select text-dark" id="role" aria-describedby="role" onChange={(e) => setNewCrew({ ...newCrew, role: e.target.value })} value={newCrew.role}>
                                        <option value="" disabled selected="selected">--- Pilih Hak Akses ---</option>
                                        <option value="2">Kost Owner</option>
                                        <option value="3">Finance</option>
                                        <option value="4">Staff</option>
                                    </select>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger text-light" onClick={hideModalCrewAdd}>Batal</button>
                                <button type="submit" onClick={handleSubmitAdd} className="btn btn-primary">Tambah</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" ref={refModalCrewEdit} tabIndex="-1" >
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header bg-primary text-light">
                                <h5 className="modal-title"><i className="mdi mdi mdi-home-plus-outline"></i> Ubah Pengguna</h5>
                                <button type="button" className="btn btn-sm text-light" onClick={hideModalCrewEdit}>X</button>
                            </div>
                            <div className="modal-body">
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="id-pengguna">ID Pengguna</span>
                                    <input type="text" className="form-control" disabled id="id-pengguna" aria-describedby="id-pengguna" onChange={(e) => setEditCrew({ ...editCrew, id: e.target.value })} value={editCrew.id}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="nama-depan-pengguna">Nama Depan</span>
                                    <input type="text" className="form-control" id="nama-depan-pengguna" aria-describedby="nama-depan-pengguna" placeholder="Nama Depan Pengguna" onChange={(e) => setEditCrew({ ...editCrew, firstname: e.target.value })} value={editCrew.firstname}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="nama-belakang-pengguna">Nama Belakang</span>
                                    <input type="text" className="form-control" id="nama-belakang-pengguna" aria-describedby="nama-belakang-pengguna" placeholder="Nama Belakang Pengguna" onChange={(e) => setEditCrew({ ...editCrew, lastname: e.target.value })} value={editCrew.lastname}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="username">Crewname</span>
                                    <input type="text" className="form-control" id="username" aria-describedby="username" placeholder="Crewname" onChange={(e) => setEditCrew({ ...editCrew, username: e.target.value })} value={editCrew.username}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="password">Password</span>
                                    <input type="password" className="form-control" id="password" aria-describedby="password" placeholder="Password" onChange={(e) => setEditCrew({ ...editCrew, password: e.target.value })} value={editCrew.password}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="confirm-password">Konfirmasi Password</span>
                                    <input type="password" className="form-control" id="confirm-password" aria-describedby="confirm-password" placeholder="Confirm Password" onChange={(e) => e.target.value.length > 3 && e.target.value === editCrew.password ? e.target.className = "form-control bg-success" : e.target.className = "form-control bg-danger"}></input>
                                </div>
                                <div className="input-group input-group-sm mb-3">
                                    <span className="input-group-text" id="role">Hak Akses</span>
                                    <select className="form-select text-dark" id="role" aria-describedby="role" onChange={(e) => setEditCrew({ ...editCrew, role: e.target.value })} value={editCrew.role}>
                                        <option value="" disabled selected="selected">--- Pilih Hak Akses ---</option>
                                        <option value="2">Kost Owner</option>
                                        <option value="3">Finance</option>
                                        <option value="4">Staff</option>
                                    </select>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger text-light" onClick={hideModalCrewEdit}>Batal</button>
                                <button type="submit" onClick={handleSubmitEdit} className="btn btn-primary">Ubah</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div>

    );
}
export default Karyawan;
