// App.js

import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { AuthProvider } from "middleware/AuthContext";
import PrivateRoute from "middleware/PrivateRoute";

import Header from "components/Header";
import Sidebar from "components/Sidebar";
import Footer from "components/Footer";

import Login from "pages/Login";
import Beranda from "pages/Beranda/Index";
import Kamar from "pages/Kamar/Index";
import Penghuni from "pages/Penghuni/Index";
import Pengguna from "pages/Pengguna/Index";
import Karyawan from "pages/Karyawan/Index";

function App() {
    
    return (
      <div>
        <AuthProvider>
          <Router>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/beranda"
                element={
                  <PrivateRoute>
                    <div className="container-fluid page-body-wrapper">
                      <Header />
                      <Sidebar />
                      <div className="main-panel m-4">
                        <Beranda />
                      </div>
                    </div>
                    <Footer />
                  </PrivateRoute>
                }
              />
              <Route
                path="/kamar"
                element={
                  <PrivateRoute>
                    <div className="container-fluid page-body-wrapper">
                      <Header />
                      <Sidebar />
                      <div className="main-panel m-4">
                        <Kamar />
                      </div>
                    </div>
                    <Footer />
                  </PrivateRoute>
                }
              />
              <Route
                path="/penghuni"
                element={
                  <PrivateRoute>
                    <div className="container-fluid page-body-wrapper">
                      <Header />
                      <Sidebar />
                      <div className="main-panel m-4">
                        <Penghuni />
                      </div>
                    </div>
                    <Footer />
                  </PrivateRoute>
                }
              />
              <Route
                path="/pengguna"
                element={
                  <PrivateRoute>
                    <div className="container-fluid page-body-wrapper">
                      <Header />
                      <Sidebar />
                      <div className="main-panel m-4">
                        <Pengguna />
                      </div>
                    </div>
                    <Footer />
                  </PrivateRoute>
                }
              />
              <Route
                path="/karyawan"
                element={
                  <PrivateRoute>
                    <div className="container-fluid page-body-wrapper">
                      <Header />
                      <Sidebar />
                      <div className="main-panel m-4">
                        <Karyawan />
                      </div>
                    </div>
                    <Footer />
                  </PrivateRoute>
                }
              />
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          </Router>
        </AuthProvider>
      </div>
    );
}

export default App;
