
import React, { useState, useEffect } from "react";
import {axiosClient} from 'api/axiosClient';
import RoomsDetail from "components/RoomsDetail";

function Beranda() {

  const [users, setUserInfo] = useState({});
  const [rooms_summary, setRoomSummary] = useState({});
  const [rooms_detail, setRoomDetail] = useState([{}]);
  const [announcement, setAnnouncement] = useState([{}]);

  const today = new Date().getDate() + "/" + new Date().getMonth() + "/" + new Date().getFullYear();
  const fetchUser = async () => {
    try {
      const response = await axiosClient.get(`/users`);
      setUserInfo(response.data.data);
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  };

  const fetchBeranda = async () => {
    try {
      const response = await axiosClient.get(`/api/beranda`);
      setRoomSummary(response.data.data.rooms_summary[0]);
      setRoomDetail(response.data.data.rooms_desc);
      setAnnouncement(response.data.data.announcement);
    } catch (error) {
      console.error("Error fetching beranda:", error);
    }
  };

  const roomListA = rooms_detail.map(r => { //harusnya pakai filter
    if(r.roomclass === 'A')
      return <RoomsDetail data={r} />
  });

  const roomListB = rooms_detail.map(r => {
    if (r.roomclass === 'B')
      return <RoomsDetail data={r} />
  });

  const roomListVIP = rooms_detail.map(r => {
    if (r.roomclass === 'VIP')
      return <RoomsDetail data={r} />
  });


  useEffect(() => {
    fetchUser();
    fetchBeranda();

  }, []);
  return (
    <div>
      <div className="row">
        <div className="col-md-12 grid-margin">
          <div className="row">
            <div className="col-12 col-xl-6 mb-4 mb-xl-0">
              <h3 className="font-weight-bold">Apa kabar, {users.firstname}?</h3>

            </div>
            <div className="col-12 col-xl-6 mr-4">
              <div className="justify-content-end d-flex">

                <i className="mdi mdi-calendar"></i> Hari ini, {today}


              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card bg-warning">
            <div className="card-body">
              <p className="card-title">
                <i className="mdi mdi-information-outline menu-icon"></i> Pengumuman
              </p>
              <div id="textCarousel" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <div class="d-flex justify-content-center align-items-center">
                      <p class="text-center h6"></p>
                    </div>
                  </div>
                  {announcement ? announcement.map(a=>(
                    <div class="carousel-item" key={a.id}>
                      <div class="d-flex justify-content-center align-items-center">
                        <p class="text-center h6">{a.dates} : {a.description}</p>
                      </div>
                    </div>
                  )): null}
                  
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card">
          <div className="card">
            <div className="card-body">
              <p className="card-title bg-primary">
                <button type="button" className="btn btn-primary">
                  <b> <i className="mdi mdi-home-group menu-icon"></i> Kamar</b>
                </button>
              </p>
              <div className="row">
                <div className="col-md-3 mb-4 stretch-card transparent">
                  <div className="card bg-info">
                    <div className="card-body">
                      <p className="mb-4">Total Kamar</p>
                      <p className="fs-30 mb-2">{rooms_summary.total_rooms}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 mb-4 stretch-card transparent">
                  <div className="card bg-warning">
                    <div className="card-body">
                      <p className="mb-4">Kamar Terisi</p>
                      <p className="fs-30 mb-2">{rooms_summary.rented}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 mb-4 stretch-card transparent">
                  <div className="card bg-secondary">
                    <div className="card-body">
                      <p className="mb-4">Kamar Kosong</p>
                      <p className="fs-30 mb-2">{rooms_summary.available}</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 mb-4 stretch-card transparent">
                  <div className="card bg-dark text-light secondary">
                    <div className="card-body">
                      <p className="mb-4">Kamar Tahap Renovasi</p>
                      <p className="fs-30 mb-2">{rooms_summary.maintenance}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                  <div className="col-md-6">
                    {roomListB}
                  </div>
                  <div className="col-md-6">
                    {roomListA}
                  </div>
                  <div className="col-md-12">
                    {roomListVIP}
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
}
export default Beranda;