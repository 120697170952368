
import React, {  useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../middleware/AuthContext';
import {axiosClient} from '../api/axiosClient';
import Swal from 'sweetalert2'

const Login = () => {
        const { login } = useAuth();
        const [username, setUsername] = useState('');
        const [password, setPassword] = useState('');
        const navigate = useNavigate();

        const handleSubmit = async (event) => {
            event.preventDefault();
            try {
                const response = await axiosClient.post('/auth', { username, password });
                localStorage.setItem('token', response.data.data);
                login();
                navigate("/beranda");
                Swal.fire({
                    title: "Berhasil Masuk!",
                    text: `Selamat datang, ${username}!`,
                    icon: "success",
                    timer: 1500
                });

            } catch (error) {
                console.error('Login error:', error);
                Swal.fire({
                    title: "Kesalahan!",
                    text: `Login gagal, username atau password salah!`,
                    icon: "error",
                    timer: 1500
                });
            }
            
        };
        return (
            <div>
                <div className="container-scroller">
                    <div className="container-fluid page-body-wrapper full-page-wrapper">
                        <div className="content-wrapper d-flex align-items-center auth px-0">
                            <div className="row w-100 mx-0">
                                <div className="col-lg-4 mx-auto">
                                    <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                                        <div className="brand-logo">
                                            <img src="assets/images/aman-logo.png" alt="logo" />
                                        </div>
                                        <h4>AmanKost (Aplikasi Management Kost)</h4>
                                        <h6 className="font-weight-light">Selamat datang! Silahkan login.</h6>
                                        
                                        <form className="pt-3" onSubmit={handleSubmit}>
                                            <div className="input-group">
                                                <span className="input-group-text bg-info" id="icon-username"><i className="mdi mdi-account"></i></span>
                                                <input
                                                    type="text"
                                                    className="form-control form-control-md"
                                                    placeholder="Username"
                                                    aria-label="Username" aria-describedby="icon-username"
                                                    onChange={(e) => setUsername(e.target.value)}
                                                    value={username}
                                                />
                                            </div>
                                            <div className="input-group">
                                                <span className="input-group-text bg-info" id="icon-pwd"><i className="mdi mdi-lock"></i></span>
                                                <input
                                                    type="password"
                                                    className="form-control form-control-md"
                                                    placeholder="Password"
                                                    aria-label="Username" aria-describedby="icon-pwd"
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    value={password}
                                                />
                                            </div>
                                            <div className="mt-3 d-grid gap-2">
                                                <button type="submit"
                                                    className="btn btn-block btn-info btn-lg font-weight-medium auth-form-btn"
                                                >
                                                    Login
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

export default Login;